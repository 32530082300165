@import "./hornet_colors";

body.role-user {
	margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6E6C73;
    text-align: left;
	background-color: #F7F7F7;

	&.user-notary {
		background-color: $hornet-neutral-0;
	}
	
	.text-blue {
		color:$hornet-blue;
	}
	.main-top-navbar{
		border-bottom:2px solid $hornet-blue;
		padding: 0 1rem;
		&.bg-light {
			background-color:#fff !important;
		}
		.nav-item{
			line-height:2.5rem;
			font-weight:600;
		}
		.dropdown-item {
			line-height:2.5rem;
		}
	}
	.navbar {
		.navbar-brand {
			img {
				height:39px;
			}
		}
		.nav-item {
			.fa {
				width:20px;
				margin-right:5px;
			}
		}
	}
	.content-header {
		margin:20px 0;
		.header-icon {
			display:inline-block;
		}
		.header-title {
			h1 {
				font-size: 22px;
				font-weight:600;
				margin-bottom: 0;
			}
			small {
				font-size:16px;
				font-weight:600;
				color:$hornet-gray;
			}
		}
	}

	// Card
	.card {
		margin-bottom: 20px;
		.card-header {
			background-color: $hornet-neutral-0;
			border-bottom: none;
			font-size: 18px;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom:0;
			.card-title {
				margin-bottom:0;
			}
		}
	}
}

// Mobile 
@media (max-width: 1074px) {
	body.role-user {
		.navbar-collapse{
			overflow-x: auto;
			max-height: calc(100vh - 67px);
		}
	}
}


// Desktop
@media (min-width: 1074px) {
	body.role-user {
		.main-top-navbar{
			border-bottom:2px solid $hornet-blue;
			padding: 0 1rem;
			&.bg-light {
				background-color:#fff !important;
			}
			.nav-item{
				line-height:2.5rem;
				font-weight:600;
			}
			// .dropdown-item {
			//     line-height:1.5rem;
			// }
		}
		.navbar {
			.nav-item.dropdown.show {
				color:#fff;
				background:$hornet-blue;
				.dropdown-toggle{
					color:#fff;
				}
			}
	
			.navbar-brand {
				img {
					height:39px;
				}
			}
			.nav-item {
				.fa {
					width:20px;
					margin-right:5px;
				}
			}
			.dropdown-menu {
				box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
			}
		}
		.content-header {
			margin:20px 0;
			.header-icon {
				display:inline-block;
			}
			.header-title {
				h1 {
					font-size: 22px;
					font-weight:600;
					margin-bottom: 0;
				}
				small {
					font-size:16px;
					font-weight:600;
					color:$hornet-gray;
				}
			}
		}
	}
}