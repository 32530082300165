@media (min-width: 1074px) {
	.main-top-navbar.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem;
		font-size: .9rem;
		position: relative;

		/* this applies for the desktop navigation dropdown items only */
		.badge.badge-danger {
			position: absolute;
			top: 12px;
			width: 18px;
			height: 18px;
			line-height: 18px;
			border-radius: 50%;
			display: inline-block;
			text-align: center;
			font-weight: normal;
			font-size: 10px;
		}
	}
}

