@import "../vars";

.empty {
  position: relative;
  min-height: 300px;
  .main {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: $hornet-light-gray;
    .iconBox {
      font-size: 36px;
    }
    .textBox {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
