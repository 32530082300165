@import "../_vars.scss";

.buttonBox {
  height: $top-bar-height-admin;
  width: 40px;
  line-height: $top-bar-height-admin;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  background: $hornet-blue;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background: $hornet-dark-blue;
  }
}

.loadingBox {
 height: 200px;
}
.body {

}