@import "../../../style/application/hornet_colors";

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 20px;

  .cardSize {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.actionBarButtons {
  margin-right: 5px;
  color: $hornet-neutral-0;
}

.callBlinker {
  animation: blinker .75s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
