@import "../../../../style/application/hornet_colors";

.quill {
  // for placeholder (Fields Dropdown only)
  .ql-toolbar .ql-placeholder {
    .ql-picker-options {
      padding: 0;
      height: 200px;
      overflow-y: auto;
      .ql-picker-item {
        padding: 4px 8px;

        &.is-group {
          font-weight: bold;
          color: $hornet-dark;
          background: $hornet-light-gray;
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }

  // SHOW ONLY view
  &.show-only {

    //removing border from container
    .ql-container {
      border: 0;

      .ql-editor {
        padding-top: 0;
        padding-bottom: 0;

        &.ql-blank::before {
          content: '-'
        }
      }
    }
  }

  // INVALID view
  &.is-invalid {
    .ql-toolbar.ql-snow,
    .ql-container {
      border-color: $hornet-red;
    }
  }
}