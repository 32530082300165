@import "./vars";
$top-height: 50px;
.messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  .top {
    height: $top-height;
    background: $hornet-blue;
    color: #fff;
    display: flex;
    line-height: $top-height;
    padding: 0 8px;
    .left {
      cursor: pointer;
    }
    .right {
      flex-grow: 1;
      text-align: right;
      position: relative;
      .btn {
        color: $hornet-blue;
      }
      .ellipsis {
        position: absolute;
        top: 0;
        left: 10px;
        right: 10px;
        bottom: 0;
        line-height: $top-height;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: right;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .bottom {
    position: relative;
    flex-grow: 1;
  }
  &.isAdmin {
    .top {
      background: $hornet-dark-blue;
    }
  }
}

.chatList {
  .title {
    color: $hornet-gray;
    margin: 8px 10px 0 10px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;

  }
  .adminHeader {
    margin: 10px;
    display: flex;
    .selectBox {
      flex-grow: 1;
    }
    .tools {
      text-align: right;
      padding-left: 10px;
      a {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}
