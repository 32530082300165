@import "../../../style/application/hornet_colors";

.dropdown {
  margin-left: 5px;
  .dropdownToggle {
    color:#fff;
  }
}
//
//@media (max-width: 450px) {
//  .dropdown {
//    margin-left: 0;
//  }
//}

