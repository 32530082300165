.userTypingLoader {
  display: inline-block;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
  font-size: 1.50rem;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}
