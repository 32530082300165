@import "../../../style/application/hornet_colors";

.view-file-modal {
  height: 100%;
  padding: 1.75rem;
  z-index: 2001;

  .modal-dialog {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    min-height: 400px;

    iframe {
      border: 1px solid $hornet-light-gray;
      width: 100%;
      height: 100%;
    }
  }
}