.wrapper {
  text-align: center;
  position: relative;
  height: 120px;
  width: 100%;
}

.wrapperAbsolute {
  pointer-events: all;
  transition: all 0.3s ease;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
}