
@import "./application/application";

// Eventually need to move everything here
@import "./custom_modal";
@import "./custom_scrollbar";
.spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

$ig-blue: $hornet-blue;
@import "~react-image-gallery/styles/scss/image-gallery.scss";
.image-gallery-content .image-gallery-slide .image-gallery-image {
  // max-height: calc(100vh - 80px);
  max-height: 500px;
}

.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
  // max-height: 400px;
}

.fixChecks {
  display: block;
  .form-check-input {
    margin-top: 5px !important;
  }
  .form-check-label {
    padding-left: 20px;
  }
}
@import "./application/datePickerOverride";

