@import "../../../style/application/hornet_colors";

.main-page {
  .navbar {
    .navbar-brand {
      img {
        height: 60px;
      }
    }

    &.main-top-navbar {
      border-bottom: 2px solid $hornet-blue;
      padding: 0 1rem;

      &.bg-light {
        background-color: #fff !important;
      }

      .nav-item {
        line-height: 2.5rem;
        font-weight: 600;
      }
    }
  }
}

.drop-zone {
  width: 100%;
  height: 180px;
  border: 2px dashed #ccc;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.task-details-container {
  margin: 15px 0 0 0;

  .task-description-label {
    position: absolute;
    top: 8px;
    left: 28px;
    font-size: 14px;
    background-color: $hornet-neutral-0;
    padding: 0 5px;
    border-radius: 3px;
  }

  .task-description-value {
    border: 1px solid #ccc;
    padding: 10px;
    height: 100% !important;
  }
}


.list {
  height: 180px;
  overflow-y: auto;

  .list-item {
    display: flex;
    align-items: center;

    .item-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 52px);
      padding: 5px 1px;
      font-weight: bold;
    }
  }
}

.header-icon-style {
  cursor: pointer;
  margin-left: 10px;
}

.task-header-icon {
  width: 20px;
  height: 20px;
  color: $hornet-blue;
}