@import "../../../style/application/hornet_colors";

.widget {
  .title {
    font-size: 1.1em;
    font-weight: 600;
    color: $hornet-blue;
    text-transform: uppercase;
  }

  .value {
    font-size: 1.1em;
  }
  .empty {
    opacity: 0.5;
  }
  .loading {
    opacity: 0.5;
  }
}