.sidebar {
  .counter {
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-weight: normal;
    font-size: 10px;
    padding: 0;
  }

  &.collapsed:not(:hover) .counter {
    position: absolute;
    top: 0;
    right: 2px;
  }
}