@import "../../../../variables";

/* BaseSelectBox */

.container {
	margin-top: 2px;
	margin-bottom: 2px;
	//position: relative;

	:global(.base-select-box__control) {
		min-height: 40px;
		@include typeography-body-medium();
		border-color: $fill-gray-40;
		border-radius: 6px;
		background-color: $fill-white-100;
		padding: 0px 0px 0px 10px;
		cursor: pointer;
	}
	:global(.base-select-box__single-value) {
		color: $fill-gray-100;
	}
	:global(.base-select-box__indicators) {
	}

	:global(.base-select-box__indicator-separator) {
		background-color: $fill-gray-40;
	}
	:global(.base-select-box__indicator) {
		color: $fill-gray-40;
	}

	:global(.base-select-box__menu){
		overflow: hidden;
		z-index: 1300;
		max-height: 300px;
	}

}
