
.dynamic-col {
	position:relative;
	display: inline-block;
	margin-bottom:10px;
	.btn {
		cursor:pointer;

	}
	.dynamic-col-dropdown {
		display:none;
		background:#fff;
		position:absolute;
		top:100%;
		right:0;
		z-index: 1;
		padding:10px 20px;
		text-align: left;
		box-shadow: 0 3px 20px rgba(0,0,0,0.2);
		border-radius: 5px;
		white-space: nowrap;
		.form-group{
			padding-left:26px;
		}
	}
}