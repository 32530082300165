/************************************************
 * BOOTSTRAP TEMPORARY FIX FOR OVERFLOW
 *
 * SEE: Victoria French
 * 
 * When using .table-responsive, Bootstrap 4 sets
 * the overflow-x to 'auto'. This causes a
 * clipping issue with popups. The app does not
 * appear to be creating tables that require
 * vertical scrolling, so setting the value back
 * to visible until a more perminant fix can be
 * applied.
 ************************************************/

.table-responsive {
	overflow-x: visible;
}
