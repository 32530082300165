@import "./bootstrap_vars";

.Modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: auto;
	padding-bottom: 30px;

	.bg {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(255, 255, 255, 0.8);
	}

	.content {
		background: #fff;
		position: absolute;
		top: 100px;
		left: 50%;
		transform: translateX(-50%);
		width: 600px;
		max-width: 90%;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
		padding: 10px 20px 20px;

		.title {
			padding: 10px 20px;
			border-bottom: 2px solid $hornet-blue;
			font-size: 18px;
			font-weight: 600;
		}

		.body {
			padding: 10px 20px;
		}

		.foot {
			padding: 10px 20px;
		}
	}
}