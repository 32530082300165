@import "../../../style/application/hornet_colors";

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 20px;
}

.margin-right-custom {
  margin-right: 2rem;
}
