@import "../../../style/application/_hornet_colors.scss";

.tableRow {
  position: relative;
}

.actionCell {
  width: 0% !important;
  padding: 0% !important;
  margin: 0% !important;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  right: -20px;
}

.actionBar {
  position: absolute;
  top: 50%;
  right: 0;
  white-space: nowrap;
  background: $hornet-offwhite;
  padding: 5px;
  display: none;
  border: 1px solid #dee2e6;
  border-top-left-radius: 4px;
  border-right: 0;
  border-bottom-left-radius: 4px;
  transform: translateY(-50%);

  button:not(:last-child) {
    margin-right: 5px;
  }
}

.tableRow {
  //&.odd {
  //  background-color: #f3fbff;
  //}

  &.expanded {
    transition: 3s linear;
  }

  &:hover, &:hover + .expanded {
    & > td > .actionBar:not(:empty) {
      display: block;
    }

    //background-color: lighten($hornet-blue, 55%);

    &:nth-of-type(odd) {
      // background-color: #f3fbff; // set in bootstrap
      //background-color: lighten($hornet-blue, 55%);
    }

    //&.odd {
    //  background-color: lighten($hornet-blue, 55%);
    //}
  }
}

.dateFilterBox {
  padding: 8px;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 100%;
  z-index: +1;

  position: absolute;
  top: 100%;
  margin-top: 5px;
  min-width: 264px;
  white-space: nowrap;
  // padding: 5px 5px 10px;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 1000;
}

.dateFilterLabel {
  font-size: 10px;
  margin-bottom: 0;
}

.editAccept {
  margin-left: -20px;
}

.editCancel {
  background: $hornet-dark;
  color: #fff;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: -15px;
  margin-left: 20px;
  transform: translateX(-50%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  line-height: 30px;
  transition: all 0.3s ease;
}

.calendarSetNullBox {
  text-align: center;
  margin: 10px 0;
}

.showHideActionBarContainer {
  display: inline-block;
  position: relative;
  .showHideActionBarButton, .showHideActionBarButtonInactive {
    margin-right: 5px;
    position: relative;
  }

  .showHideActionBarButtonInactive {
    opacity: 0.5;
  }
}

.hiddenActionBar {
  display: none !important;
}