@import "../../../../style/application/hornet_colors";

.fixChecks {
  display: block;

  .form-check-label {
    padding-right: 16px;
  }
}

.checkboxGroup {

}

select {

  &.noAppearance {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.showOnlyValue {
  margin-left: 10px;
  font-style: italic;
  font-size: smaller;

  &:empty:before {
    content: '-';
  }
}

.dropContainer, .dropContainerDrag {
  width: 100%;
  height: 160px;
  outline: 2px dashed $hornet-gray;
  margin: 0 0 20px;
  border-radius: 5px;
  position: relative;
  background: $hornet-offwhite;
  transition: all 0.3s ease;

  .dropContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    .dropIcon {
      font-size: 42px;
    }

    .dropText {
      font-weight: 400;
      color: $hornet-gray;
    }
  }
}
