.rowCustom {
  width: 420px;

  .centeredTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;

    .centeredText {
      height: 70px;
      width: 70px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      font-size: 50px;
      text-align: center;
      line-height: 70px; // Vertically center the text inside the circle
    }
  }
}
