@import "./hornet_colors";

.login-wrapper {
  // margin: 0;
  // font-family: "Roboto", sans-serif;
  // font-size: 1rem;
  // font-weight: 400;
  // line-height: 1.5;
  // color: #6e6c73;
  // text-align: left;
  // background-color: #f7f7f7;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  .login-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;

    .with-pre-group {
      label {
        left: 45px;
        width: 75%;

        &.has-value {
          left: 45px;
          width: auto;
        }
      }

      input:focus ~ label {
        width: auto;
        left: 45px;
      }
    }

    .login-logo {
      text-align: center;
      margin-bottom: 10px;

      img {
        width: 200px;
        max-width: 200px;
        height: auto;
      }
    }

    .card-heading.custom_head {
      // background-color: $hornet-blue;
      padding: 20px 20px;
      text-align: center;
      background: #fbfbfb;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);

      h3 {
        color: $hornet-blue;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 0;
      }

      small {
        color: $hornet-gray;
        text-align: center;
      }

      // color:#fff;
    }

    .form-control {
      border: 1px solid #ced4da;
    }

    .login-btns {
      text-align: center;
    }

    .below-card {
      text-align: center;
      margin-top: 10px;
    }

    a.btn-secondary {
      color: #fff;
    }

    .btn-warning {
      background-color: #F79324;
      color: #FFF;
    }
  }
}

.input-handling {
  .with-pre-group {
    .cross-clear-hide {
      width: 0;
      height: 0;
      padding: 0;
    }

    .invalid-circle {
      right: 25px;
    }

    label {
      &.has-value {

        ~ .cross-clear-hide {
          width: 25px;
          height: 25px;
          padding: 5px 5px;
        }

        ~ .invalid-circle {
          right: 45px;
        }
      }
    }
  }
}

.login-container,.register-container,.forgot-password-container,.forgot-username-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background-color: #FFF;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    @media (max-width: 768px) {
      height: 100%;
    }
  }

  .logo {
    width: 250px; /* Adjust size as needed */
    margin-bottom: 20px;
  }

  .login-form ,.registerForm{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    label{
      left: 10px;
    }
    h4 {
      color: $hornet-blue;
      font-weight: bold;
    }

    .btn-warning {
      background-color: #F79324;
    }

    .cross-clear-hide {
      width: 0;
      height: 0;
      padding: 0;
    }

    .cross-clear {
      position: absolute;
      top: 40%;
      right: -22px;
      transform: translateY(-50%);
      cursor: pointer;
    }
    button{
      color: #FFF;
      font-weight: bold;
      margin-left: 1%;
      width: 98%;
    }
  }

  .footer {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    width: 100%;
    background-size: cover;
  }


}
.register-container .mb-3{
  margin-bottom: .75rem !important;
}
.forgot-password-container,.forgot-username-container {
  .floating-form-group .input-group .cross-clear {
    top: 47%;
  }
}