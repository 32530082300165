@import "../../../../style/application/hornet_colors";

.base-select-box {
  .base-select-box__option--is-selected,
  .base-select-box__option--is-selected:active {
    background-color: $hornet-gray;
  }

  .base-select-box__option--is-selected.base-select-box__option--is-focused {
    background-color: $hornet-gray-focused
  }

  .base-select-box__value-container {
    max-height: 200px;
    overflow: auto;
  }
}

.input-group .base-select-box:not(:last-child) .base-select-box__control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
