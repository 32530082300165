@import "../../../style/application/hornet_colors";

.property-show-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .nav-tabs .nav-link {
    padding: 10px;
    font-size: 14px;

    &.active,
    &:hover,
    &:focus {
      background-color: transparent;
      border-color: transparent;
      border-bottom: 2px solid $hornet-dark;
    }

    &.active {
      border-bottom: 2px solid $hornet-dark-blue;
    }
  }

  .tab-content {
    min-height: 90vh;
    overflow: auto;
    padding: 0 20px;
  }
}