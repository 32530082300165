@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

// Variables
@import "~bootstrap/scss/functions";
@import "./hornet_colors";
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Colors

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem;
$table-cell-padding-sm:       .3rem;

$table-color:                 $body-color;
$table-bg: $hornet-neutral-0;
$table-accent-bg:             lighten($hornet-blue, 60%);
$table-hover-color:           $table-color;
$table-hover-bg:              lighten($hornet-blue, 50%);
$table-active-bg:             $table-hover-bg;

$table-border-width:          $border-width;
$table-border-color:          $border-color;

$table-head-bg:               $hornet-blue;
$table-head-color: $hornet-neutral-0;

$table-dark-color:            $white;
$table-dark-bg:               $gray-800;
$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-color:      $table-dark-color;
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($table-dark-bg, 7.5%);
$table-dark-color:            $white;

$table-striped-order:         odd;

$table-caption-color:         $text-muted;

$table-bg-level:              -9;
$table-border-level:          -6;

.table thead th {
	background:$table-head-bg;
	color:$table-head-color;
	font-weight:600;
	padding: 0.4rem 0.75rem;
}