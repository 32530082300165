@import "../../style/application/hornet_colors";

.timelineSteps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1rem;

  .timelineStep {
    align-items: center;
    display: flex;
    position: relative;
    flex: 1;

    .timelineContent {
      width: 8rem;
      display: inline-block;
      white-space: nowrap;
      flex: 1;
    }

    &:not(:last-child):after {
      content: "";
      display: inline-block;
      border-top: 0.25rem solid $hornet-gray;
      width: 100%;
      vertical-align: middle;
      margin-left: 2px;
    }

    &.success:after {
      border-top-color: $hornet-green;
    }

    &.danger:after {
      border-top-color: $hornet-red;
    }

    &.warning:after {
      border-top-color: $hornet-orange;
    }
  }
}

.stepBar {
  .icon {
    color: $hornet-neutral-200;
  }

  .line {
    border: 2px solid $hornet-neutral-50;
    z-index: -1;
    position: absolute;
    top: 12px;
    width: 100%;
    left: 50%;
  }
}
