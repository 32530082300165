/* FIGMA FILLS */
$fill-white-100: rgba(255, 255, 255, 1);
$fill-white-72: rgba(255, 255, 255, .72);
$fill-white-40: rgba(255, 255, 255, .4);
$fill-gray-100: rgba(32, 37, 50, 1);
$fill-gray-80: rgba(80, 84, 94, 1);
$fill-gray-60: rgba(131, 135, 145, 1);
$fill-gray-40: rgba(190, 192, 197, 1);
$fill-gray-10: rgba(217, 218, 221, 1);
$fill-gray-05: rgba(234, 234, 235, 1);
$fill-gray-0: rgba(242, 242, 243, 1);
$fill-gray-disabled: $fill-gray-60;
$fill-primary-100: rgba(0, 123, 192, 1);
$fill-primary-50: rgba(128, 189, 223, 1);
$fill-primary-20: rgba(204, 229, 242, 1);
$fill-primary-10: rgba(230, 242, 249, 1);
$fill-primary-05: rgba(242, 248, 252, 1);
$fill-primary-0: rgba(252, 254, 254, 1);
$fill-success-100: rgba(57, 132, 75, 1);
$fill-success-50: rgba(156, 193, 165, 1);
$fill-success-05: rgba(235, 243, 237, 1);
$fill-warning-100: rgba(247, 147, 36, 1);
$fill-warning-50: rgba(251, 201, 145, 1);
$fill-warning-05: rgba(255, 250, 244, 1);
$fill-alert-100: rgba(185, 88, 86, 1);
$fill-alert-50: rgba(220, 171, 170, 1);
$fill-alert-05: rgba(251, 247, 247, 1);

/* FIGMA SHADOWS */
@mixin shadow-large {
	box-shadow: 0px 2px 12px rgba(26, 26, 26, 0.1), 0px 16px 24px -2px rgba(26, 26, 26, 0.12);
}
@mixin shadow-medium {
	box-shadow: 0px 6px 20px -2px rgba(26, 26, 26, 0.14), 0px 4px 6px rgba(26, 26, 26, 0.06);
}
@mixin shadow-small {
	box-shadow: 0px 2px 8px rgba(32, 37, 50, 0.08), 0px 2px 4px rgba(32, 37, 50, 0.03);
}

/* FIGMA TYPEOGRAPHY */
$font-family-primary: "Inter";
$font-family-code: "Overpass Mono";

@mixin typeography-base-primary {
	font-family: $font-family-primary;
	font-style: normal;
}

@mixin typeography-base-code {
	font-family: $font-family-code;
	font-style: normal;
}

@mixin typeography-h900 {
	/* Portal/Primary/H900 */
	@include typeography-base-primary;
	font-weight: 300;
	font-size: 72px;
	line-height: 108px;
	/* identical to box height, or 150% */
}
@mixin typeography-h800 {
	/* Portal/Primary/H800 */
	@include typeography-base-primary;
	font-weight: 300;
	font-size: 56px;
	line-height: 84px;
	/* identical to box height, or 150% */
}
@mixin typeography-h700 {
	/* Portal/Primary/H700 */
	@include typeography-base-primary;
	font-weight: 300;
	font-size: 40px;
	line-height: 60px;
	/* identical to box height, or 150% */
}
@mixin typeography-h600 {
	/* Portal/Primary/H600 */
	@include typeography-base-primary;
	font-weight: 300;
	font-size: 32px;
	line-height: 48px;
	/* identical to box height, or 150% */
}
@mixin typeography-h500 {
	/* Portal/Primary/H500 */
	@include typeography-base-primary;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	/* identical to box height, or 150% */
}
@mixin typeography-h400 {
	/* Portal/Primary/H400 */
	@include typeography-base-primary;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	/* identical to box height, or 160% */
}
@mixin typeography-h300 {
	/* Portal/Primary/H300 */
	@include typeography-base-primary;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
}
@mixin typeography-h200 {
	/* Portal/Primary/H200 */
	@include typeography-base-primary;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
}
@mixin typeography-h100 {
	/* Portal/Primary/H100 */
	@include typeography-base-primary;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */
}
@mixin typeography-subtitle {
	/* Portal/Primary/Subtitle */
	@include typeography-base-primary;
	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
	/* identical to box height, or 160% */
}
@mixin typeography-body-large {
	/* Portal/Primary/Body Large */
	@include typeography-base-primary;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
	letter-spacing: 0.2px;
}
@mixin typeography-body-medium {
	/* Portal/Primary/Body Medium */
	@include typeography-base-primary;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	letter-spacing: 0.2px;
}
@mixin typeography-body-small {
	/* Portal/Primary/Body Small */
	@include typeography-base-primary;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */
	letter-spacing: 0.2px;
}
@mixin typeography-action-large {
	/* Portal/Primary/Action Large */
	@include typeography-base-primary;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
}
@mixin typeography-action-medium {
	/* Portal/Primary/Action Medium */
	@include typeography-base-primary;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
}
@mixin typeography-action-small {
	/* Portal/Primary/Action Small */
	@include typeography-base-primary;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */
}
@mixin typeography-code-large {
	/* Portal/Primary/Code Large */
	@include typeography-base-code;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */
}
@mixin typeography-code-medium {
	/* Portal/Primary/Code Medium */
	@include typeography-base-code;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
}
@mixin typeography-code-small {
	/* Portal/Primary/Code Small */
	@include typeography-base-code;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */
}

@mixin button-like-medium {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	background: $fill-white-100;
	border: 1px solid $fill-gray-40;
	@include typeography-action-medium;
	color: $fill-gray-100;
	padding: 9px 20px;
	min-width: 120px;
	cursor: pointer;
	transition: 0.2s all;

	&[disabled] {
		cursor: default;
		color: $fill-gray-60;
	}

	&:active {
		transform: scale(0.88);
		opacity: 0.5;
	}

	&:hover {
		border-color: $fill-gray-100;
	}

	&.primary {
		background-color: $fill-primary-100;
		border-color: $fill-primary-100;
		color: $fill-white-100;

		&:hover {
			border-color: $fill-primary-100;
		}
	}
}

@mixin button-like-small {

}

@mixin button-like-large {

}

@mixin textbox-like-medium {
	box-sizing: border-box;
	display: flex;
	height: 40px;
	background: $fill-white-100;
	border: 1px solid $fill-gray-40;
	box-sizing: border-box;
	border-radius: 6px;
	padding: 10px 16px;
	color: $fill-gray-100;
	margin-top: 2px;
	margin-bottom: 2px;
	@include typeography-body-medium();

	&.alert {
		border-color: #B95856;

		&:focus,
		&:focus-visible,
		&:focus-within {
			outline: 1px solid #B95856 !important; /* for non-webkit browsers */
			/*outline: 5px auto #B95856 !important;*/
		}
	}

	&:focus,
	&:focus-visible,
	&:focus-within {
		border-color: #007BC0;
		outline: 1px solid #007BC0 !important; /* for non-webkit browsers */
		/*outline: 5px auto -webkit-focus-ring-color !important;*/
	}

	&[disabled] {
		color: #838791;
	}
}

@mixin textbox-like-small {
	@include textbox-like-medium();
	padding: 8px 12px;
	height: 32px;
}

@mixin textbox-like-large {
	@include textbox-like-medium();
	padding: 12px 16px;
	height: 48px;
}

@mixin shimmer {
	background: #f6f7f8;
	background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
	background-repeat: no-repeat;
	background-size: 800px 104px;
	display: inline-block;
	position: relative; 
	animation-duration: 1s;
	animation-fill-mode: forwards; 
	animation-iteration-count: infinite;
	animation-name: placeholderShimmer;
	animation-timing-function: linear;
}

@keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0; 
	}
}