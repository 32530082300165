@import "../../../../../style/application/_hornet_colors.scss";

.pageBox {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	background: #fff;
	margin-bottom: 20px;

	[class~=svg-doc],
	[class~=svg-page],
	[class~=react-pdf__Page__svg],
	svg {
		width: 100% !important;
		height: 100% !important;
	}

}

.pageNumberBox {
	font-size: 14px;
	margin: 10px 0 20px;
}

$bottom-bar-height: 55px;

.buttonBoxWrapper {
	bottom: 0;
	position: absolute;
	right: 10px;
	top: 0;
}

.bottomSpacer {
	height: $bottom-bar-height;
}
