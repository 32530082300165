@import "../../../style/application/_hornet_colors.scss";

.instructionLink {
  color: $hornet-blue;
}

//
.instructions {

  .name {
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    //color: $hornet-gray;
  }

  .downloadBox {
    text-align: center;
  }
}


.bankInfo {
  table {
    margin: 20px auto 10px;
    th, td {
      padding: 0 8px;
    }
    th {
      text-align: right
    }
  }
}
.editButton {
  text-align: center;
  color: $hornet-blue;
  cursor: pointer;
}