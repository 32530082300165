@import "../../../../style/application/hornet_colors";

.is-invalid .base-select-box__control {
  border-color: $hornet-red;
}

.floating-form-group {
  &.searchable-select {
    .input-group.with-pre-group {
      label {
        top: 28px; // Initial position
        left: 10px
      }

      &.focused, &.has-value { // Updated selector syntax
        label {
          left: 10px;
          top: 10px; // Adjusted position when focused or has a value
        }
      }
    }
    .invalid-circle {
      right: 65px;
    }
  }
  .base-select-box__placeholder {
    margin-left: 5px;;
  }

  .base-select-box__multi-value {
    margin: 1px !important;
  }
}

.base-select-box .base-select-box__control--is-focused {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 192, 0.25);

  &:hover {
    border-color: #41bbff;
  }

  .base-select-box .base-select-box__multi-value {
    margin: 1px;
  }
}

.mr-doc-focused {
  label {
    left: 10px;
    top: 10px; // Adjusted position when focused or has a value
  }
}

