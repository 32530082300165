@import "../../../style/application/hornet_colors";
.searchModal {
  .modalHeader:after {
    margin: 0px -15px;
    width: 100%;
  }

  .modalTitle {
    width: 90%;
    .searchBarContainer {
      display: flex;
      align-items: center;

      .searchBar {
        flex: 1;
        margin-right: 5px;
      }
    }

  }
  .modalBody {
    padding-left: 0;
    padding-right: 0;
  }

  .resultContainer {
    border-bottom: 1px solid $hornet-dark;
    position: sticky;
    top: 0;
    background: $hornet-neutral-0;
    z-index: 1;

    .addIconContainer {
      font-size: 22px;
      float: right;
      display: inline-flex;

      svg {
        vertical-align: super;
        margin-right: 10px;
        color: $hornet-dark;
      }
    }
  }

  .tabs {
    height: 66vh;
    overflow-y: auto;
  }
  .customScrollBar{
    border-right: 1px solid black;
    width: 100%;
  }

  .searchBar {
    background: 0 center;
    border: 0;
    opacity: 1;
    outline: 0;
    padding: 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    height: 24px;
    cursor: text;
    width: 100%;
    color: #6E6C73;
  }

  .cardBody {
    height: 63vh;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .searchContainer {
    max-width: 81.5%;
    max-height: 66vh;
    overflow-y: auto;
    position: relative;

    .searchItem {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: outset;
      border-bottom-color: #dee2e660;
      padding: 5px 10px;
      width: 100%;

      .iconContainer {
        flex: 0 0 5%;
        max-width: 5%;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        position: relative;

        .recentIcon {
          bottom: -5px;
          font-size: small;
          position: absolute;
          left: -2px;
        }
      }

      .SearchContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-width: calc(100% - (5% + 10px));

        .title {
          font-weight: 500;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 500px;
        }

        .textDescription {
          text-decoration: none;
          color: #6E6C73;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 20px);

          .textDescriptionItem {
            min-width: 0;
            margin-right: 0.5rem;
            font-size: 12px;

            &::before {
              content: "•";
              color: var(--cu-content-tertiary);
              margin-right: 0.25rem;
              font-size: 14px;
            }
          }
        }

        .linkSection {
          display: none;
          align-items: center;
          justify-content: flex-end;
        }
      }

      &:hover {
        background-color: #e2e6ea40;

        .linkSection {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

