@import "./hornet_colors";
@import "./bootstrap_vars";

$bst-mobile-footer-height: 67px;
$bst-mobile-footer-sort-height: 32px;

.table thead th {
	background: none;
	color: $hornet-text;
	border: 0;
}

.smart-table {
	background: $hornet-neutral-0;

	.clickable {
		color: $hornet-blue;
		cursor: pointer;
	}

	.table-responsive > .table {
		color: $hornet-text;
		margin-bottom: 0;

		.multi-select {
			width: 100%;
			position: relative;
			background: #fff;
			height: 33px;
			color: #495057;
			border: 1px solid #ced4da;
			border-radius: 0.2rem;
			cursor: pointer;
			min-width: 50px;

			.ms-text {
				white-space: nowrap;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				height: 100%;
				overflow: hidden;
				line-height: 33px;
				user-select: none;
				padding: 0 5px;
			}

			.caret {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				background-image: linear-gradient(
												to right,
												rgba(255, 255, 255, 0),
												rgba(255, 255, 255, 1),
												rgba(255, 255, 255, 1),
												rgba(255, 255, 255, 1)
				);
				line-height: 33px;
				width: 20px;
				text-align: center;
				pointer-events: none;
				color: #666;
			}

			.ms-dropdown {
				position: absolute;
				top: 100%;
				margin-top: 5px;
				min-width: 150px;
				white-space: nowrap;
				padding: 5px 5px 10px;
				background: #fff;
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
				border-radius: 5px;
				z-index: 1000;
			}

			.ms-selects {
				margin-top: 10px;
				padding: 0 10px 0;
				overflow-x: auto;
				height: 150px;

				/* width */
				&::-webkit-scrollbar {
					width: 8px;
				}

				/* Track */
				&::-webkit-scrollbar-track {
					background: #f1f1f1;
					border-radius: 4px;
				}

				/* Handle */
				&::-webkit-scrollbar-thumb {
					background: #d6d6d8;
					border-radius: 4px;
				}

				/* Handle on hover */
				&::-webkit-scrollbar-thumb:hover {
					background: #c9c9cc;
				}
			}

			.ms-clear-btn {
				position: absolute;
				top: 0;
				left: 100%;

				.btn {
					border-top-right-radius: 0.2rem !important;
					border-bottom-right-radius: 0.2rem !important;
				}
			}

			hr {
				margin: 5px 0 7px;
			}
		}

		.editable-col {
			position: relative;

			// overflow:hidden;
			.col-edit-btn-box {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				overflow: hidden;
				pointer-events: none;

				.col-edit-btn {
					position: absolute;
					right: 0;
					top: 5px;
					bottom: 5px;
					width: 31px;
					background: $hornet-offwhite;
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					text-align: center;
					margin-right: -37px;
					cursor: pointer;
					transition: all 0.3s ease;
					box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
					pointer-events: all;

					.col-edit-icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
					}
				}
			}

			&:hover {
				.col-edit-btn-box {
					.col-edit-btn {
						margin-right: 0;
					}
				}
			}

			.edit-box {
				position: absolute;
				bottom: 24px;
				margin-bottom: -10px;
				background: $hornet-neutral-0;
				padding: 20px;
				border-radius: 5px;
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
				transition: all 0.3s ease;
				z-index: 100;
				left: 50%;
				transform: translateX(-50%);

				.accept-btn-box {
					content: "";
					width: 30px;
					height: 30px;
					position: absolute;
					top: 100%;
					left: 50%;
					margin-top: -15px;
					transform: translateX(-50%);
					box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
					background: $hornet-blue;
					border-radius: 50%;
					cursor: pointer;
					color: $hornet-neutral-0;
					text-align: center;
					line-height: 30px;
					transition: all 0.3s ease;
				}
			}
		}

		.truncate {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 1px;
		}

		.one-line {
			white-space: nowrap;
		}

		.react-calendar {
			width: 248px;
			border: none;
			line-height: 1rem;
			font-family: "Roboto", sans-serif;
			color: $hornet-dark;

			.react-calendar__tile {
				padding: 0.3em 0.3em;
			}

			.react-calendar__tile--active {
				background: $hornet-blue;
			}

			.react-calendar__tile--active:enabled:hover,
			.react-calendar__tile--active:enabled:focus {
				background: $hornet-blue;
			}

			.react-calendar__navigation button {
				min-width: 30px;
				height: 26px;
			}

			.react-calendar__navigation {
				height: 25px;
				margin-bottom: 5px;
			}
		}

		& > thead th {
			text-transform: uppercase;
			border-bottom-width: 1px;
			background: $hornet-blue-t;
			color: $hornet-blue;
			overflow: hidden;
			position: relative;
			padding: 0 0 0 0.75rem;
			font-size: .75rem;

			.th-body {
				width: 100%;
				height: 100%;
				position: relative;
				padding-right: 26px;
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;
			}

			.head-tools {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				display: flex;
				height: 16px;
				font-size: 12px;
				line-height: 16px;
				background: $hornet-blue-t;
				transition: all 0.3s ease;
				margin-top: -16px;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;

				.head-tool-btn {
					text-align: center;
					margin: 0 5px;
					width: 16px;
					cursor: pointer;
				}
			}

			&:hover {
				.head-tools {
					margin-top: 0;
				}
			}
		}

		.table-filter-row {
			background: $hornet-offwhite;
		}

		td, .sum-row th {
			font-size: .875rem;
		}

		.expand-collapse-button {
			cursor: pointer;
			color: $hornet-blue;
		}
	}

	.footer-area {
		display: flex;
		align-items: center;
		justify-content: end;
		background: $hornet-blue-t;
		color: $hornet-blue;
		font-size: .75rem;
		font-weight: 600;
		min-height: 40px;

		.pagination {
			margin-bottom: 0;

			.page-item {
				.page-link {
					background: none;
					border: 0;
					padding: .75rem;
				}

				&.active .page-link {
					color: $hornet-dark;
				}

				&.line-height-1 .page-link {
					line-height: 1;
					height: 100%;
				}
			}
		}
	}
}

$stm-vis-item-height: 45px;
.smart-table-mobile {
	.drag-reorder-grp {
		ul {
			list-style: none;
			padding: 0;
		}
	}

	.card {
		margin-bottom: 5px !important;
		.mobile-card-body {
			padding: 10px;
			.sort-by-box {
				display: flex;
				font-size: 12px;
				background: #f7f7f7;
				border: 1px solid#DFDFDF;
				border-radius: 5px;
				line-height: 12px;
				.item-key {
					color: $hornet-gray;
					text-align: right;
					flex-grow: 1;
					padding: 5px;
				}
				.item-content {
					color: $hornet-dark;
					text-align: left;
					flex-grow: 1;
					padding: 5px;
				}
			}
		}
	}
	.mobile-col-title {
		color: $hornet-gray;
		font-size: 12px;
	}
	.collapse-content {
		// background: #f7f7f7;
		// border: 1px solid#DFDFDF;
		padding: 10px;
		border-radius: 5px;
		overflow: hidden;
		margin-top: 10px;
		box-shadow: inset 0 2px 10px rgba(0,0,0,0.15);
	}
	.collapse-btn-container {
		height: 40px;
		width: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 21px;
		cursor: pointer;
	}
	.bst-m-vis-item {
		background: #fff;
		border: 1px solid $hornet-light-gray;
		border-radius: 5px;
		margin-bottom: 5px;
		display: flex;
		height: $stm-vis-item-height;
		font-size: 18px;
		line-height: $stm-vis-item-height;
		.side-icon {
			height: $stm-vis-item-height;
			line-height: $stm-vis-item-height;
			text-align: center;
			width: 36px;
			color: $hornet-gray;
			cursor: grab;
			i {
				margin: 0 1px;
			}
		}
		.toggle-holder {
			margin: 10px 10px;
			.toggle-bg {
				width: 40px;
				height: 22px;
				border-radius: 11px;
				background: $hornet-gray;
				position: relative;
				transition: all 0.3s ease;
				cursor: pointer;
				.toggle-nob {
					width: 20px;
					height: 20px;
					background: #fff;
					border-radius: 10px;
					position: absolute;
					top: 1px;
					left: 1px;
					transition: all 0.3s ease;
				}
				&.active {
					background: $hornet-green;
					.toggle-nob {
						left: calc(100% - 21px);
					}
				}
			}
		}
	}
}

.form-control {
	&.bst-filter {
		&:not(:placeholder-shown) {
			border: 1px solid $success;
		}
	}
}

.bst-placeholder-append {
	position: absolute;
	top: 0;
	right: 30px;
	line-height: 38px;
	color: $success;
	max-width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: right;
}

.bst-mobile-footer-spacer {
	height: $bst-mobile-footer-height + $bst-mobile-footer-sort-height;
}
.bst-mobile-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	height: $bst-mobile-footer-height + $bst-mobile-footer-sort-height;
	padding-top: $bst-mobile-footer-sort-height;
	border-top: 2px solid $hornet-blue;
	z-index: 2000;
	display: flex;
	color: $hornet-gray;
	.bst-footer-sort {
		height: $bst-mobile-footer-sort-height;
		background: $hornet-blue;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		line-height: $bst-mobile-footer-sort-height;
		padding: 0 20px;
		display: flex;
		.select-group {
			padding: 0 18px;
			select {
				// A reset of styles, including removing the default dropdown arrow
				appearance: none;
				// Additional resets for further consistency
				background-color: transparent;
				border: none;
				padding: 0 1em 0 0;
				margin: 0;
				width: 100%;
				font-family: inherit;
				font-size: inherit;
				cursor: inherit;
				line-height: inherit;
				outline: none;
				color: #fff;
				text-align: center;

				&::-ms-expand {
					display: none;
				}

				option {
					color: $hornet-dark;
					text-align: center;
				}
			}
		}
	}
	.bst-mobile-item {
		height: 100%;
		text-align: center;
		flex-grow: 1;
		cursor: pointer;
		transition: all 0.3s ease;
		&.active {
			color: $hornet-blue;
		}
		.bmi-icon {
			font-size: 24px;
			margin-top: 5px;
		}
		.bmi-text {
			font-size: 16px;
			line-height: 16px;
			font-weight: 500;
			text-transform: uppercase;
		}
	}
}

.loan-status-lights {
	height: 40px;
	width: 16px;
	.light {
		width: 8px;
		height: 8px;
		margin: 1px;
		border-radius: 4px;
		background: $secondary;
		&.success {
			background: $success;
		}
		&.warning {
			background: $warning;
		}
		&.danger {
			background: $danger;
		}
		&.primary {
			background: $primary;
		}
	}
	&.horizontal {
		height: 16px;
		width: 40px;
		.light {
			display: inline-block;
		}
	}
}
