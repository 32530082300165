
.container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	flex-grow: 1;
	box-sizing: border-box;
}

.image {
	display: flex;
	width: 120px;
	height: 120px;
	padding: 20px;
	box-sizing: border-box;
}

.heading {
	display: flex;
	font-size: 1.5em;
	box-sizing: border-box;
}

.body {
	display: flex;
	padding: 20px;
	max-width: 500px;
	box-sizing: border-box;
	text-align: center;
}