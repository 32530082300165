@import "../vars";

$badge-size: 18px;

.badge,
.badgeSpace {
  display: inline-block;
  width: $badge-size;
  height: $badge-size;
  margin: 0 3px;
}
.badge {
  background: $hornet-red;
  border-radius: $badge-size * 0.5;
  color: #fff;
  line-height: $badge-size;
  font-size: 10px;
  text-align: center;
}

.taskItem {
  background: #fff;
  border: 1px solid $hornet-gray;
  padding: 10px;
  margin: 8px 10px 0 10px;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  align-items: stretch;
  cursor: pointer;
  &.complete {
    opacity: 0.5;
  }
  .left {
    flex-grow: 1;
    .userName {
      font-weight: 600;
      color: $hornet-blue;
    }
    .name {
      font-weight: 600;
    }
    .statusBox {
      display: flex;
      .title {
        font-size: 10px;
        line-height: 16px;
        color: $hornet-light-gray;
      }
      .status {
        font-size: 12px;
        margin-left: 5px;
        font-weight: 600;
        line-height: 16px;
        &.pending {
          color: $hornet-green;
        }
        &.active {
          color: $hornet-orange;
        }
        &.hidden{
          color: $hornet-gray;
        }
        &.complete {
          color: $hornet-blue;
        }
      }
    }
    .messageTextBox {
      position: relative;
      height: 20px;
      .messageText {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: $hornet-gray;
        .empty {
          color: $hornet-light-gray;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
  .badgeBox {
    position: relative;
    width: 20px;
  }
  .sideArrow {
    position: relative;
    color: $hornet-gray;
    font-size: 20px;
    width: 20px;
  }
}

.vAlign {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.messages {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: $message-input-height;
  overflow: auto;
  padding: 10px;
  .note,
  .message {
    margin-bottom: 8px;
  }
  .note {
    .noteDate {
      opacity: 0.5;
      font-size: 10px;
      text-align: center;
    }
    .noteText {
      text-align: center;
      font-size: 12px;
    }
  }
  .message {
    .messageName {
      font-size: 10px;
      padding: 0 5px;
    }
    .messageBox {
      color: $hornet-dark;
      background: $hornet-light-gray;
      width: 85%;
      border-radius: 5px;
      padding: 4px 12px 8px;
      position: relative;
      .messageDate {
        opacity: 0.5;
        font-size: 10px;
        text-align: center;
      }

      .messageAction {
        color: $hornet-light-gray;
        position: absolute;
        margin-right: 5px;
        cursor: pointer;
      }

      .messageEdit {
        @extend .messageAction;
        top: 0;
        right: 0;
      }

      .messageDelete {
        @extend .messageAction;
        top: -1px;
        right: 22px;
      }

      .messageContent {
        font-size: 14px;
        font-weight: 400;
      }

      .disabledAction {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &.isFromUser {
      .messageName {
        text-align: right;
      }
      .messageBox {
        color: #fff;
        background: $hornet-blue;
        margin-left: auto;
        margin-right: 0;
        &.editing {
          background: $hornet-orange;
        }
        .messageDate {
        }
        .messageContent {
        }
      }
    }
  }
}

.messageInput {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  height: $message-input-height;
  .btnBox {
    margin-top: 10px;
  }
}

.menuItem {
  padding: 8px 10px;
  font-size: 22px;
  cursor: pointer;
  position: relative;
  width: 42px;
  svg {
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
  }
  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
  .badgeHolder {
    position: absolute;
    top: 50%;
    left: 50%;
    padding-left: 18px;
    padding-bottom: 8px;
    transform: translateX(-50%) translateY(-50%);
    line-height: 0;
  }
  &.isAdmin {
    padding: 0 10px;
    width: 100%;
    height: 100%;
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
.loanId {
  color: $hornet-blue;
  .title {
    display: inline-block;
    font-size: 10px;
    line-height: 16px;
    color: $hornet-light-gray;
  }
}

.lastWord {
  text-align: center;
  small {
    display: block;
    font-size: 10px;
    margin: 20px 0 5px;
  }
}
