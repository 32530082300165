@import "./vars";

.item {
  background: #fff;
  border: 1px solid $hornet-gray;
  padding: 10px;
  margin: 8px 10px 0 10px;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  align-items: stretch;
  cursor: pointer;
  .left {
    flex-grow: 1;
    .userName {
      font-weight: 600;
      color: $hornet-blue;
    }
    .name {
      font-weight: 600;
    }
    .statusBox {
      display: flex;
      table {
        th{
          font-size: 10px;
          line-height: 16px;
          color: $hornet-light-gray;
          text-align: right;
          padding-right: 8px;
        }
        td {
          font-size: 12px;
          margin-left: 5px;
          font-weight: 600;
          line-height: 16px;
        }
      }
      .title {
        font-size: 10px;
        line-height: 16px;
        color: $hornet-light-gray;
      }
      .status {
        font-size: 12px;
        margin-left: 5px;
        font-weight: 600;
        line-height: 16px;
      }
    }
    .messageTextBox {
      position: relative;
      height: 20px;
      .messageText {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
  .badgeBox {
    position: relative;
    width: 20px;
  }
  .sideArrow {
    position: relative;
    color: $hornet-gray;
    font-size: 20px;
    width: 20px;
  }
}
.vAlign {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}