$hornet-text: #25213B;
$hornet-dark: #6E6C73;
$hornet-gray: #ACA9B4;
$hornet-gray-focused: #929095;
$hornet-light-gray: #DFDFDF;
$hornet-green: #28a745;
$hornet-offwhite: #F7F7F7;

$hornet-blue: #007BC0;
$hornet-blue-t: #007BC026;
$hornet-blue-border: #006fad;
$hornet-dark-blue: #046299;
$hornet-orange: #f79323;
$hornet-red: #DC3545;
$hornet-dark-red: #C82333;
$hornet-black: #000000;
$hornet-black-0-25: rgba(0, 0, 0, 0.25);

$hornet-neutral-0: #FFF;
$hornet-neutral-50: #E9EBEC;
$hornet-neutral-50-40: #E9EBEC66;
$hornet-neutral-100: #BAC0C5;
$hornet-neutral-200: #99A1A9;
$hornet-neutral-300: #6A7681;
$hornet-neutral-400: #4D5C69;
$hornet-neutral-500: #213343;
$hornet-neutral-600: #1E2E3D;
$hornet-neutral-700: #172430;
$hornet-neutral-800: #121C25;
$hornet-neutral-900: #0E151C;

$hornet-red-alert: #C63918;
$hornet-orange-alert: #DE350B;
$hornet-orange-alert-light: rgba(222, 53, 11, 0.77);

$hornet-blue-50: #E6F2F9;
$hornet-blue-100: #B1D6EC;
$hornet-blue-200: #8BC2E2;
$hornet-blue-300: #55A7D5;
$hornet-blue-400: #3595CD;
$hornet-blue-500: #027BC1;
$hornet-blue-700: #015789;

$hornet-green-500: #00AC3A;
$hornet-blue-hover: #027BC2;
$hornet-blue-border-100: #B1D6EC;
$hornet-blue-500-50: rgba(2, 123, 193, 0.50);
