@import "../../../variables";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
	margin: 0;
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.route-enter {
	opacity: 0;
}
.route-enter-active {
	opacity: 1;
	transition: opacity 200ms;
}
.route-exit {
	visibility: hidden;
	--opacity: 1;
}
.route-exit-active {
	visibility: hidden;
	--opacity: 0;
	--transition: opacity 200ms;
}

.btn.no-shadow:focus {
	box-shadow: none;
}

.Toastify__toast-container {
	max-height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

[class*="Toastify__toast-container--"] {
	padding-top: 1em;
	padding-bottom: 1em;
}

.Toastify__toast-container--top-right {
	top: 0;
	right: 0;
}

.Toastify__toast-container--top-left {
	top: 0;
	left: 0;
}

.Toastify__toast-container--bottom-right {
	bottom: 0;
	right: 0;
}

.Toastify__toast-container--bottom-left {
	bottom: 0;
	left: 0;
}
