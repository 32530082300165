@import "./hornet_colors";
@import "./bootstrap_vars";

.profile {
	.table-tool {
		padding: 2px 4px;
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
	input[type="radio"],
	input[type="checkbox"] {
		box-sizing: border-box;
		padding: 0;
		margin: 0 6px 0 0;
	}
	h3 {
		margin-top: 30px;
		border-bottom: 3px solid rgba(0, 123, 192, 0.5);
		margin-bottom: 30px;
	}
	.click-to-edit {
		display: inline-block;
		position: relative;
		padding-top: calc(0.375rem + 1px);
		.ico {
			position: absolute;
			left: 100%;
			top: 0;
			margin-left: 10px;
			opacity: 0;
			transition: all 0.3s ease;
			cursor: pointer;
			padding-top: calc(0.375rem + 1px);
		}
		&:hover {
			.ico {
				opacity: 0.5;
			}
		}
		&.locked {
			.ico {
				cursor: not-allowed;
				opacity: 0.5;
			}
		}
		.new-email {
			color: $hornet-orange;
			font-size: 12px;
			margin-left: 10px;
		}
	}
}

// rablet and mobile
@media (max-width: 991px) {
	.profile {
		h3 {
			font-size: 18px;
			margin-top: 18px;
			margin-bottom: 14px;
		}
		.form-group {
			background: #fff;
			border: 1px solid $hornet-light-gray;
			border-radius: 5px;
			margin-bottom: 5px;
			padding: 5px 0;
			position: relative;
			overflow: hidden;
			&.row {
				margin-right: 0;
				margin-left: 0;
			}
			.col-form-label {
				font-size: 12px;
				color: $hornet-gray;
				padding-bottom: 0;
				padding-top: 0;
			}
			.ico-ssn-eye {
				display: none !important;
			}
			.swipe-delete-btn {
				background: $danger;
				color: #fff;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 80px;
				text-align: center;
				font-size:18px;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				margin-right: -80px;
				transition: all 0.3s ease;
				cursor: pointer;
				&.open {
					margin-right: -30px;
				}
				i {
					position: absolute;
					top:50%;
					left:25px;
					transform: translateY(-50%) translateX(-50%);
				}
			}
		}
		.click-to-edit {
			padding-top: 20px;
			margin-top: -18px;
			display: block;
			cursor: pointer;
			&.locked {
				cursor: not-allowed;
			}
			.ico {
				display: none;
			}
			&:hover {
				.ico {
					display: none;
				}
			}
		}
	}
}
