@import "../../../style/application/hornet_colors";

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 20px;
}

.actionBarButtons {
  margin-right: 5px;
  color: $hornet-neutral-0;
}