@import "./vars";
$top-height: 50px;
.task {
  display: flex;
  flex-direction: column;
  height: 100%;
  .top {
    height: $top-height;
    background: $hornet-blue;
    color: #fff;
    display: flex;
    line-height: $top-height;
    padding: 0 8px;
    .left {
      cursor: pointer;
    }
    .right {
      flex-grow: 1;
      text-align: right;
      &.isAdmin {
        display: flex;
      }
      .btn {
        color: $hornet-blue;
      }
      .selectBox {
        margin-top: 6px;
        padding-left: 40px;
        flex-grow: 1;
        select {
        }
      }
      .menuBtn {
        width: 40px;
        text-align: center;
        cursor: pointer;
        background: $hornet-dark-blue;
        transition: all 0.3s ease;
      }
    }
  }
  .descriptionBox {
    padding: 10px $margin-side;
    .ownerName {
      color: $hornet-blue;
      font-weight: 600;
      font-size: 18px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .content {
      font-size: 14px;
    }
    .loanId {
      font-size: 14px;
      color: $hornet-light-gray;
      font-weight: 600;
    }
  }
  .subBox {
    padding: 10px $margin-side;
    .subTop {
      display: flex;
      .left {
        flex-grow: 1;
      }
      .addFile {
        text-align: right;
        font-size: 14px;
        color: $hornet-blue;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  hr {
    margin-bottom: 0;
  }
  a {
    font-size: 14px;
  }
  &.isAdmin {
    .top {
      background: $hornet-dark-blue;
    }
  }
}

.subTitle {
  font-size: 14px;
  font-weight: 600;
}


.taskList {
  .adminHeader {
    margin: 10px;
    display: flex;
    .selectBox {
      flex-grow: 1;
    }
    .tools {
      text-align: right;
      padding-left: 10px;
      a {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}
