@import "../../../style/application/hornet_colors";

$height: 26px;
$width: 56px;
$padding: 3px;
$nob-size: $height - $padding - $padding;


$medium-height: 18px;
$medium-width: 35px;
$medium-padding: 2px;
$medium-nob-size: $medium-height - $medium-padding - $medium-padding;
.container {
  position: relative;
  display: inline-block;

  .title {
    color: $hornet-gray;
    font-size: 10px;
  }

  .toggle {
    height: $height;
    width: $width;
    background: $hornet-red;
    position: relative;
    border-radius: calc($height / 2);
    cursor: pointer;
    transition: all 0.3s ease;

    .nob {
      background: #fff;
      height: $nob-size;
      width: $nob-size;
      position: absolute;
      top: $padding;
      border-radius: calc($nob-size / 2);
      left: $padding;
      transition: all 0.3s ease;
    }

    &.medium {
      height: $medium-height;
      width: $medium-width;
      border-radius: calc($medium-height / 2);

      .nob {
        height: $medium-nob-size;
        width: $medium-nob-size;
        top: $medium-padding;
        border-radius: calc($medium-nob-size / 2);
        left: $medium-padding;
      }
    }
  }

  &.secondary {
    .toggle {
      background-color: $hornet-neutral-100;
    }
  }

  &.on {
    .toggle {
      background: $hornet-green;

      .nob {
        left: $width - $nob-size - $padding;
      }

      &.medium {
        .nob {
          left: $medium-width - $medium-nob-size - $medium-padding;
        }
      }
    }
  }

  &.isLoading {
    .toggle {
      opacity: 0.3;
      cursor: not-allowed !important;
    }
  }

  &.disabled {
    .toggle {
      opacity: 0.3;
    }
  }

  .loadingBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: $hornet-blue;
    font-size: 18px;
  }
}
