// @import "./stylecrm";
@import './admin_vars';

body.role-admin {
	.content-header {
		margin: 20px 0;

		.header-icon {
			display: inline-block;
		}

		.header-title {
			h1 {
				font-size: 22px;
				font-weight: 600;
				margin-bottom: 0;
			}

			small {
				font-size: 16px;
				font-weight: 600;
				color: $hornet-gray;
			}
		}
	}
	.card {
		margin-bottom: 20px;
		.card-header {
			background-color: $hornet-neutral-0;
			border-bottom: none;
			font-size: 18px;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom:0;
			.card-title {
				margin-bottom:0;
			}
		}
	}

	.sidebar {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: $sidebar-width;
		background: $hornet-blue;
		color: $hornet-offwhite;
		transition: all 0.3s ease;
		z-index: 100;

		.top {
			position: absolute;
			top: 0;
			width: 100%;
			height: $topbar-height;
			border-bottom: 1px solid $hornet-blue-border;
			box-shadow: 0 0 7px 0 #00000080;
		}

		.main {
			position: absolute;
			top: $topbar-height;
			left: 0;
			bottom: 0;
			right: 0;
			overflow: auto;
			padding-bottom: 21px;
		}
		.sublink-item {
			white-space: nowrap;
			position:relative;
		}
		.sidebar-item {
			white-space: nowrap;
			position:relative;

			a {
				color: $hornet-offwhite;
				text-decoration: none;
				padding: 8px;
				display: block;
			}

			position: relative;

			.icon {
				display: inline-block;
				width: 26px;
				text-align: center;
				margin: 0 5px;
			}

			.caret {
				position: absolute;
				top: 8px;
				right: 8px;
				transition: all 0.5s ease;
				pointer-events: none;
				opacity: 1;

				&.open {
					transform: rotate(-180deg);
				}
			}

			.sublinks {
				height: 0;
				overflow: hidden;
				background: darken($hornet-blue, 5%);

				&.open {
					height: auto;
				}

				.sublink {
					padding-left: 32px;
					transition: all 0.3s ease;

					.sublink-icon {
						display: inline-block;
						width: 26px;
						text-align: center;
						margin: 0 5px;
					}
				}
			}

			.sidelink {
				position:absolute;
				display:block;
				top:0;
				right:0;
				bottom:0;
				width: 40px;
				text-align: center;
				background:rgba(0,0,0,0.0);
				transition: all 0.3s ease;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;

				&:hover {
					background: rgba(0,0,0,0.1);
				}
			}
		}

		&.collapsed {
			width: $sidebar-collapsed-width;

			.sidebar-item {
				.text {
					display: none;
				}
			}

			.caret {
				opacity: 0;
			}

			.sublinks {
				.sublink {
					padding-left: 8px;
				}
			}
			.sidelink {
				display: none;
			}

			&:hover {
				width: $sidebar-width;

				.sidebar-item {
					.text {
						display: inline-block;
					}
				}

				.caret {
					opacity: 1;
				}

				.sublinks {
					.sublink {
						padding-left: 32px;
					}
				}
				.sidelink {
					display: block;
				}

				// .sublink {
				//     opacity: 1;
				// }
			}
		}
	}

	.topbar {
		position: absolute;
		top: 0;
		left: $sidebar-width;
		height: $topbar-height;
		right: 0;
		background: $hornet-blue;
		color: $hornet-offwhite;
		display: flex;
		transition: all 0.3s ease;
		z-index: 101;
		border-bottom: 1px solid $hornet-blue-border;
		box-shadow: 10px 0 7px 0 #00000080;

		.search-btn {
			.search-icon {
				text-align: center;
				line-height: $topbar-height;
				cursor: pointer;
				display: inline-block;
			}

			.search-box {
				display: inline-block;

				input,
				select {
					width: 100%;
					padding: 4px 14px;
					margin: 8px 0px 8px 14px;
					display: inline-block;
					border: 1px solid darken($hornet-blue, 5%);
					background: darken($hornet-blue, 5%);
					border-radius: 4px;
					box-sizing: border-box;
					color: $hornet-offwhite;
					outline: none;

					&::placeholder {
						color: lighten($hornet-blue, 5%);
						;
						opacity: 1;
						/* Firefox */
					}

					&:-ms-input-placeholder {
						/* Internet Explorer 10-11 */
						color: lighten($hornet-blue, 5%);
						;
					}

					&::-ms-input-placeholder {
						/* Microsoft Edge */
						color: lighten($hornet-blue, 5%);
						;
					}

					&:focus {
						border: none;
						border: 1px solid lighten($hornet-blue, 5%);
					}
				}
			}
		}

		.expand-btn {
			width: $sidebar-collapsed-width;
			height: $topbar-height;
			line-height: $topbar-height;
			text-align: center;
			cursor: pointer;
			transition: all 0.3s ease;
		}

		.top-icon {
			width: $sidebar-collapsed-width;
			height: $topbar-height;
			line-height: $topbar-height;
			text-align: center;
			cursor: pointer;
			transition: all 0.3s ease;
			font-size: 22px;
			position: relative;

			img {
				pointer-events: none;
			}

			.top-dropdown {
				background: $hornet-offwhite;
				padding: 10px;
				border: 1px solid transparentize($hornet-dark, 0.8);
				;
				position: absolute;
				top: 100%;
				right: 0;
				pointer-events: none;
				opacity: 0;
				transition: all 0.3s ease;
				line-height: 20px;
				font-size: 16px;

				.dd-item {
					color: $hornet-dark;
					white-space: nowrap;
					text-decoration: none;
					padding: 0 8px;

					i {
						margin-right: 5px;
					}
				}
			}

			&:hover {
				.top-dropdown {
					pointer-events: all;
					opacity: 1;
				}
			}
		}

		&.collapsed {
			left: 0;

			.expand-btn {
				transform: rotate(-180deg);
			}
		}
	}

	.main-container {
		position: absolute;
		top: $topbar-height;
		left: $sidebar-width;
		right: 0;
		bottom: 0;
		overflow: auto;
		padding: 0 22px 18px;
		transition: all 0.3s ease;

		&.collapsed {
			left: $sidebar-collapsed-width;
		}
	}
	&.inFrame {
		.main-container {
			top:0;
			left:0;
			transition: none;
		}
		.back-top{
			display: none;
		}
	}

	.page-container {
		width: 100%;
		height: 100%;
		overflow: auto;
		padding: 0 20px;
	}

	.actionBarButtons {
		margin-right: 5px;
		color: $hornet-neutral-0;
	}
}

.role-admin {
	.topbar {
		.dropdown-toggle {
			color: #fff;
			padding: 14px 1rem;
		}
		.dropdown-menu {
			box-shadow: 0 2px 10px rgba(0,0,0,.2);
		}
	}
}
