@import "../../../style/application/_hornet_colors.scss";

.mapBox {
  margin-bottom: 18px;
}

.infoBox {
  .pictureBox {
    float: left;
    img{
      width: 50px;
    }
  }
  .descriptionBox {
    float: left;
    padding: 10px;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.legendBox {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
  background: none padding-box rgb(255, 255, 255);
  padding: 10px 17px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25em;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

  .legendHeading {
    font-weight: bold;
    border-bottom: 1px solid $hornet-light-gray;
    font-size: 18px;
    color: black;
    margin-bottom: 8px;
  }

  .legendItem {
    display: flex;
    margin-bottom: 5px;
    font-size: 14px;

    .legendItemIcon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      padding: 2px;

      img {
        vertical-align: baseline;
      }
    }
  }
}
