@import "../../style/application/hornet_colors";

.dropContainer, .dropContainerDrag {
  width: 100%;
  height: 160px;
  outline: 2px dashed $hornet-gray;
  margin: 20px 0;
  border-radius: 5px;
  position: relative;
  background: $hornet-offwhite;
  transition: all 0.3s ease;
  .dropContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    .dropIcon {
      font-size: 42px;
    }
    .dropText {
      font-weight: 400;
      color: $hornet-gray;
    }
  }
}

.blueText {
  color: $hornet-blue;
  font-weight: 600;
}

.dropContainerDrag {
  background: #fff;
}