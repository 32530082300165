@import "./vars";

.topBar {
  background: #fff;
  height: $top-bar-height;
  border-bottom: 2px solid $hornet-blue;
  position: relative;

  .menuItem {
    display: none;
    height: $top-bar-height;
    line-height: $top-bar-height;
    width: 50%;
    float: left;
    text-align: center;
    transition: all 0.3s ease;
    font-weight: 600;
    cursor: pointer;

    &.active {
      border-bottom: 5px solid $hornet-blue;
      color: $hornet-blue;
      display: block;
    }
  }

  &.isAdmin {
    background: $hornet-blue;
    height: $top-bar-height-admin;

    .menuItem {
      display: none;
      height: $top-bar-height-admin;
      line-height: $top-bar-height-admin;
      color: rgba(255, 255, 255, 0.6);

      &.active {
        color: #fff;
        display: block;
      }
    }
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
