@import "../../../style/application/hornet_colors";

.container {
  margin: 20px auto;
  background: #fff;
  width: 600px;
  max-width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  text-align: center;
  padding: 20px;
  .icon {
    opacity: 0.5;
    font-size: 50px;
  }
}