@import "./application/hornet_colors";

.modal-backdrop {
  background: rgba(255, 255, 255, 0.8);
}

.modal-content {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 0;
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
}

.modal-header {
  border-bottom: none;
  position: relative;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    width: calc(100% - 32px);
    border-bottom: 2px solid $hornet-blue;
  }
}

.modal-footer {
  border-top: none;
  padding: 0 1rem 1rem;
}

.pac-container {
  z-index: 1051 !important;
}

#searchModal .nav-tabs {
  padding-left: 20px;
}