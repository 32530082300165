@import "./vars";

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(60, 60, 60, 0.3);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  // TODO z-index try reverting it
  z-index: 1050;

  &.open {
    pointer-events: all;
    opacity: 1;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: $sidebar-width;
  max-width: 85%;
  background: $hornet-offwhite;
  // TODO z-index try reverting it
  z-index: 1040;
  transition: all 0.3s ease;
  margin-right: -$sidebar-width;

  &.open {
    margin-right: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    z-index: 1050;
  }
}

.main {
  position: absolute;
  top: $top-bar-height;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  &.isAdmin {
    top: $top-bar-height-admin;
  }
}
