@import "../../../style/application/hornet_colors";

.title {
  color: $hornet-blue;
  font-weight: 600;
  margin-top: 5px;
}

.smallTitle {
  color: $hornet-orange;
  font-weight: 600;
  font-size: 0.8em;
  font-style: italic;
}

.link {
  color: $hornet-blue;
  font-weight: 600;
  font-size: 0.8em;
  cursor: pointer;
  &.underLabel {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }
}