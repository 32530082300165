@import "../../../style/application/hornet_colors";

.loadingBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  .iconBox {
  }
  .text{

  }
}
.iconPart {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.spin {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.circle {
  position: relative;
  width: 100%;
  height: 100%;
  border: 4px solid $hornet-blue;
  border-top-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
