@import "../../../style/application/hornet_colors";

.dropdown {
  max-height: 300px;
  position: absolute;
  top:100%;
  min-width: 100%;
  background: #fff;
  box-shadow: 0 3px 20px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden auto;
  .item {
    display: block;
    color: $hornet-blue !important;
    padding: 3px 8px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    &.active {
      background:#e2eaef;
    }
    svg {
      color: $hornet-gray;
      transform: translateY(-2px);
    }
    small {
      color: $hornet-gray;
    }
  }

  .recentSearchLink {
    max-width: 400px;
    text-overflow: ellipsis;
  }

  &.recentSearchDropdown {
    left: auto;
    right: 0;
  }
}

.searchBar {
  width: 40%;
  margin-right: 4px;

  .searchInput {
    background: #006ba7;
    border: 1px solid #006ba7;
    border-radius: 4px !important;
    box-sizing: border-box;
    color: #f7f7f7;
    outline: none;

    &:focus {
      border: 1px solid #008bda;
      background: #006ba7;
      border-radius: 4px !important;
      box-sizing: border-box;
      color: #f7f7f7;
      outline: none;
    }

    &::placeholder {
      color: #008bda;
      opacity: 1;
    }
  }

  .searchIconBtn {
    background: transparent;
    border: unset;

    &:hover {
      background: transparent;
      border: unset;
    }
  }
}

@media (min-width: 1074px) {
  .searchBar {
    width: 50%;

    @media (max-width: 1200px) {
      &.reduced {
        width: 45%;
      }
    }
  }
}

.recentSearchBtn {
  display: none;

  @media (min-width: 1200px) {
    display: inline-block;
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    &.reduced {
      display: none;
    }
  }
}