@import "../_vars.scss";

/* TODO Make sure this style works */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparentize($hornet-offwhite, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  .iconBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
