@import "./hornet_colors";

$slider_top_bar_height: 55px;
$slider_top_bar_side_width: 40px;

.user_side_nav {
  .backdrop{
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left: 0;
    background: rgba(82, 82, 82, 0);
    z-index: 2001;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  .slider{
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    max-width: 80%;
    background: #fff;
    z-index: 2002;
    transition: all 0.3s ease;
    margin-left: -400px;

    .top_bar {
      height: $slider_top_bar_height;
      background: $hornet-blue;
      display: flex;

      .side {
        color: #fff;
        width: $slider_top_bar_side_width;
        height: $slider_top_bar_height;
        line-height: $slider_top_bar_height;
        text-align: center;
        font-size: 18px;
      }

      .center {
        flex-grow: 1;
        text-align: center;
        height:$slider_top_bar_height;
        padding: 5px;
        img{
          height:100%;
          width: auto;
        }
      }
    }
    .slider_body {
      overflow: auto;
      padding: 20px 0;
      font-size: 18px;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          &.group-title {
            font-size: 14px;
            padding: 5px 30px;
            color: $hornet-gray;
          }

          a {
            padding: 8px 2px 8px 30px;
            width: 100%;
            display: block;
            color: $hornet-dark;
            background: #fff;
            transition: all 0.5s ease;

            &:hover {
              text-decoration: none;
            }

            &.selected {
              background: $hornet-blue;
              color: #fff;
            }
          }
          &.sub {
            a {
              padding-left:45px;
            }
          }

        }
      }
    }
  }
  &.open {
    .backdrop{
      pointer-events: all;
      background: rgba(82, 82, 82, 0.5);
    }
    .slider{
      margin-left: 0px;
      .top_bar {
      }
      .slider_body {
        height: 100vh;
        overflow-y: scroll;
      }
    }
  }
}