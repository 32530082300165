.live-chat-icon {
  position: fixed;
  bottom: 3%;
  right: 17px;
  cursor: pointer;
  z-index: 1030;
  transition: all 300ms ease-in-out;

  &.footer-added {
    bottom: 16%;
    right: 1%;
  }

  &.hidden {
    right: -200px;
  }

  &.visible {
    right: 30px;
  }

  .chat-message-count {
    position: absolute;
    top: -11px;
    right: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-weight: normal;
    font-size: 10px;
  }

}