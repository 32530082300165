.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 20px;
}

.card {
  .pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}