@import "./hornet_colors";

.react-datepicker__header {
  background-color: $hornet-blue;
  color: #fff;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: rgba(255, 255, 255, 0.6);
  margin-top: 7px;
}

.react-datepicker__navigation:hover *::before {
  border-color: rgba(255, 255, 255, 1);
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #fff;
}

.react-datepicker__day, .react-datepicker__time-list-item {
  color: $hornet-dark;

  &.react-datepicker__day--outside-month.react-datepicker__day--disabled {
    opacity: 0.4;
  }

  &.react-datepicker__day--disabled {
    opacity: 0.7;
  }
}

.react-datepicker__day-name {
  color: #fff;
  line-height: 1.5rem;
  font-weight: 600;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: $hornet-blue;
  color: #fff;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: $hornet-blue;
  color: #fff;
}

.react-datepicker {
  border: none;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: $hornet-blue;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: $hornet-blue;
}

.react-datepicker__time-container {
  border-left: none;
  //box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);
  background: #fff;
}

.react-datepicker__time-container .react-datepicker__time {
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2);
}

.react-datepicker__header {
  border-bottom: none;

  &.react-datepicker__header--time {
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2);
  }
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day-names {
  // use scss to mix colors
  background: $hornet-dark-blue;
}

.react-datepicker__current-month {
  padding-bottom: 8px;
}

.floating-form-group {
  .focused {
    label {
      top: 3px;
      font-size: 13px;
      opacity: 1;
      background-color: #FFF;
      left: 8px;
      padding: 0 5px;
      min-width: inherit;
      width: auto;
      color: $hornet-blue-border;
      line-height: 1;

      &.has-value.disabled {
        &::after {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 50%;
          width: 100%;
          background: #e9ecef;
          z-index: 0;
        }

        span {
          z-index: 2;
          display: block;
          position: relative;
        }
      }
    }
  }

  .react-datepicker-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;

    .react-datepicker__input-container > div {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;

      input {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;

      }

      label {
        top: 7px;
      }
    }
  }

  .base-select-box-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;


    .focused {
      label {
        top: 3px;
        font-size: 13px;
        opacity: 1;
        background-color: #FFF;
        left: 8px;
        padding: 0 5px;
        min-width: inherit;
        line-height: 1;

        &.has-value.disabled {
          &::after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50%;
            width: 100%;
            background: #e9ecef;
            z-index: 0;
          }

          span {
            z-index: 2;
            display: block;
            position: relative;
          }
        }
      }
    }

    .base-select-box {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;

      input {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;

      }

      label {
        top: 7px;
      }
    }
  }
  .async-box-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    .async-select{
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0;
      [id^="react-select"]{
        padding-left: 5px;
      }
    }

    .focused {
      label {
        top: 3px;
        font-size: 13px;
        opacity: 1;
        background-color: #FFF;
        left: 8px;
        padding: 0 5px;
        min-width: inherit;
        line-height: 1;

        &.has-value.disabled {
          &::after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50%;
            width: 100%;
            background: #e9ecef;
            z-index: 0;
          }

          span {
            z-index: 2;
            display: block;
            position: relative;
          }
        }
      }
    }

    .base-select-box {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;

      input {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;

      }

      label {
        top: 7px;
      }
    }
  }
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 3;
  background: #FFF;
  width: 97.7%;
  top: 70px;
  .title{
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translate(-50%);
    background: #FFF;
    padding: 0 10px;
  }
  li {
    padding: 2px;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
    img, svg {
      width: 15px;
      height: 15px;
      margin: 5px;
    }
  }
}