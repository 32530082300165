@import "../_variables.scss";

.ScrollbarsCustom-Thumb {
  background-color: $fill-primary-50 !important;
}

.ScrollbarsCustom-Track {
  background-color: $fill-primary-20 !important;
}

.ScrollbarsCustom-Content {
  .btn {
    &.btn-primary, &.btn-primary.disabled, &.btn-primary:disabled {
      font-size: 16px;
      @media (max-width: 1440px) {
        padding-right: 1px;
        padding-left: 1px;
        font-size: 15px;
      }
      @media (max-width: 769px) {
        padding-right: 0;
        padding-left: 0;
        word-wrap: break-word;
        font-size: 14px;
      }
    }
  }
}