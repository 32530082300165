body.role-user .navbar {
  .subMenuLink.nav-item.dropdown {
    a.nav-link {
      color: #6E6C73;
      font-weight: 400;
      font-size: 1rem;
      padding: 0.25rem 1.5rem;
    }

    .badge.badge-danger {
      margin-left: 1.5rem;
    }

    &.show {
      background: transparent;

      .dropdown-menu {
        display: none;
      }
    }

    &:hover {
      background: #cacdcf;

      & > .dropdown-menu {
        display: block;
        top: 0;
        left: 100%;

        .badge.badge-danger {
          margin-left: 0.5rem;
        }
      }
    }
  }
}