@import "../../../variables";

.spiderToggleSwitch {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: #ddd;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  perspective: 500px;
  font-size: small;

  &.disabled {
    cursor: not-allowed;
  }

  .spiderSelector {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: $fill-success-100;
    border-radius: 30px;
    transition: left 0.3s ease, width 0.3s ease;
    z-index: 0;

    &.disabled {
      background-color: $fill-success-50;
    }
  }

  .spiderOption {
    position: relative;
    text-align: center;
    font-weight: bold;
    z-index: 1;
    padding: 6px 12px;
    transition: transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;

    &.disabled {
      pointer-events: none;
    }

    &.active {
      color: white;
      transform: translateZ(1px) scale(1);
    }
  }
}
